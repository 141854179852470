import { PortableText, Link } from '@sans-souci/components'
import { ErrorPageResponse } from '@sans-souci/models'
import {
  getErrorPage,
  getSiteConfiguration,
} from '@sans-souci/services/sanity-service'
import { styled } from '@sans-souci/styles'
import { GetStaticProps, NextPage } from 'next'
import { DEFAULT_LOCALE_ID, REVALIDATE_NOT_FOUND } from '@sans-souci/configs'

const Root = styled('div', {
  minHeight: 'calc(100vh - $headerHeightMobile)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$gray500_01',
  '@md': {
    minHeight: 'calc(100vh - $headerHeightDesktop)',
  },

  a: {
    whiteSpace: 'nowrap',
    $projectFont: 'dev',
    borderRadius: '$rMax',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: `fill 200ms, color 200ms, border-color 200ms, background-color 200ms`,
    paddingLeft: '$24',
    paddingRight: '$24',
    height: '$48',

    background: '$gray500',
    color: 'white',
    '&:hover': {
      borderColor: '$gray400',
      background: '$gray400',
      color: 'white',
    },
  },
})
const Content = styled('div', {
  gap: '$32',
  textAlign: 'center',
  maxWidth: '$maxWidthL',
})
const Title = styled('h1', {
  $projectFont: 'heading02',
  color: '$gray500',
  marginBottom: '$32',
  $lin: {
    fontSize: [40, 140],
  },
})
const Description = styled('div', {
  marginBottom: '$32',
  color: '$gray300',
})

const Custom404: NextPage<ErrorPageResponse> = ({ pageData }) => {
  if (!pageData)
    return (
      <Root>
        <Title>Error 404</Title>
      </Root>
    )

  return (
    <Root>
      <Content>
        {pageData.title && <Title>{pageData.title}</Title>}
        <Description>
          {pageData.description && (
            <PortableText value={pageData.description} />
          )}
        </Description>
        {pageData.ctaLabel && pageData.ctaLink && (
          <Link {...pageData.ctaLink}>{pageData.ctaLabel}</Link>
        )}
      </Content>
    </Root>
  )
}

export const getStaticProps: GetStaticProps<ErrorPageResponse> = async ({
  locale = DEFAULT_LOCALE_ID,
  preview = false,
}) => {
  const siteConfiguration = await getSiteConfiguration(locale, preview)
  const pageData = await getErrorPage(locale, preview, 'notFound')
  return {
    props: {
      siteConfiguration,
      pageData,
      showFooter: true,
    },
    revalidate: REVALIDATE_NOT_FOUND,
  }
}

export default Custom404
